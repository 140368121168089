<template>
  <div class="container-projeto" v-if="!loading">
    <div class="header">
      <div class="button-container">
        <button class="button_nova" @click="voltarProjetos()">
          <span class="material-symbols-outlined icone-voltar"> reply </span>
          Voltar
        </button>
      </div>
      <div class="button-box">
        <!-- <div class="button-container">
          <div class="file is-boxed is primary">
            <label class="file-label text-reader">
              <input
                type="file"
                ref="file"
                class="file-input"
                id="child-input"
                name="fileName"
                multiple
                @input="handleFileUpload('append', $event)"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                  Upload Arquivo Append
                </span>
              </span>
            </label>
          </div>
        </div> -->
      </div>
    </div>

    <div class="detalhes-projeto" v-if="Object.keys(objetoProjeto).length != 0">
      <div class="card dados">
        <div class="row-card">
          <div class="item titulo">
            <span class="titulo">
              <span
                class="material-symbols-outlined icone-mensagem"
                id="mensagem-usuario"
                v-if="objetoProjeto.MensagemAoUsuario"
              >
                emergency_home
              </span>
              <b-tooltip
                v-if="objetoProjeto.MensagemAoUsuario"
                target="mensagem-usuario"
                triggers="hover"
                placement="bottom"
                variant="secondary"
                class="tooltipResults"
                boundary="window"
              >
                {{ objetoProjeto.MensagemAoUsuario }}
              </b-tooltip>
              Projeto:
              <span class="texto">
                {{ objetoProjeto.NomeProjeto }}
              </span>
            </span>
            <span class="desc bloco">
              {{ objetoProjeto.IdentificadorProjeto }}
              <span
                class="material-symbols-outlined icone-info-projeto"
                v-clipboard:copy="objetoProjeto.IdentificadorProjeto"
              >
                note_alt
              </span>
            </span>
          </div>
        </div>
        <div class="row-card">
          <div class="col-card">
            <div class="item">
              <span class="titulo data">Data de criação:</span>
              <span class="texto">
                {{ formatarData(objetoProjeto.DataCriacaoProjeto) }}
              </span>
            </div>
            <div class="item">
              <span class="titulo">Status:</span>
              <span class="texto">
                {{ objetoProjeto.StatusId }}
              </span>
              <!-- <span class="desc">
                ({{ formatarData(objetoProjeto.UltimoStatusEmDataBrt) }})
              </span> -->
            </div>
          </div>
          <div
            class="col-cards"
            v-if="metadadosProjeto.SumarioDiretorioEntrada"
          >
            <div class="col-card">
              <div class="item" style="justify-content: flex-end">
                <span class="titulo secao"> Diretório de entrada: </span>
              </div>
              <div class="item">
                <span class="titulo">Espaço total utilizado:</span>
                <span class="texto">
                  {{
                    formatarTamanhoArquivo(
                      metadadosProjeto.SumarioDiretorioEntrada
                        .TamanhoArquivosAtivos +
                        metadadosProjeto.SumarioDiretorioEntrada
                          .TamanhoArquivosExcluidos
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="col-card">
              <div class="item" style="justify-content: flex-end">
                <span class="titulo secao"> Diretório de saída: </span>
              </div>
              <div class="item">
                <span class="titulo">Espaço total utilizado:</span>
                <span class="texto">
                  {{
                    formatarTamanhoArquivo(
                      metadadosProjeto.SumarioDiretorioSaida
                        .TamanhoArquivosAtivos +
                        metadadosProjeto.SumarioDiretorioSaida
                          .TamanhoArquivosExcluidos
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ArquivosParaUpload
        :listaUpload="listaUpload"
        :cancelarUpload="cancelarUpload"
        :nomeArquivoTratado="nomeArquivoTratado"
        :erroUpload="erroUpload"
        @handleFileUpload="handleFileUpload"
        @uploadChunks="uploadChunks"
        @uploadTodosArquivos="uploadTodosArquivos"
        @cancelarUploadTodosArquivos="cancelarUploadTodosArquivos"
        @cancelarUploadChunks="cancelarUploadChunks"
        @deletarPreAUpload="deletarPreAUpload"
      />

      <ArquivosDeEntrada
        :guidProjeto="guidProjeto"
        :objetoProjeto="objetoProjeto"
        :metadadosProjeto="metadadosProjeto"
        :arquivosEntradaProjeto="arquivosEntradaProjeto"
        :arquivosExcluidosProjeto="arquivosExcluidosProjeto"
        :carregandoDownload="carregandoDownload"
        @recuperaProjeto="recuperaProjeto"
        @downloadArquivosSimples="downloadArquivosSimples"
        @downloadArquivosStreaming="downloadArquivosStreaming"
        @cancelarDownload="cancelarDownload"
      />
      <!-- <ArquivosExcluidos
        :guidProjeto="guidProjeto"
        :objetoProjeto="objetoProjeto"
        :metadadosProjeto="metadadosProjeto"
        :arquivosExcluidosProjeto="arquivosExcluidosProjeto"
        @recuperaProjeto="recuperaProjeto"
      /> -->
      <ArquivosDeSaida
        :guidProjeto="guidProjeto"
        :objetoProjeto="objetoProjeto"
        :metadadosProjeto="metadadosProjeto"
        :arquivosSaidaProjeto="arquivosSaidaProjeto"
      />
    </div>
  </div>
  <div v-else>
    <Loading class="spinner" />
  </div>
</template>

<script>
import { gestorProjetosService } from "@/services";
import { gestorArquivosService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import Loading from "@/components/Loading";
import JSZip from "jszip";
import ArquivosDeEntrada from "@/components/GestorBases/ArquivosDeEntrada.vue";
// import ArquivosExcluidos from "@/components/GestorBases/ArquivosExcluidos.vue";
import ArquivosDeSaida from "@/components/GestorBases/ArquivosDeSaida.vue";
import ArquivosParaUpload from "@/components/GestorBases/ArquivosParaUpload.vue";
import Swal from "sweetalert2";

export default {
  name: "ProjetosDetalhes",

  components: {
    Loading,
    ArquivosParaUpload,
    ArquivosDeEntrada,
    // ArquivosExcluidos,
    ArquivosDeSaida,
  },

  props: {},

  data() {
    return {
      guidProjeto: "",
      objetoProjeto: {},
      metadadosProjeto: {},
      arquivosEntradaProjeto: [],
      arquivosSaidaProjeto: [],
      arquivosExcluidosProjeto: [],
      loading: false,

      file: null,
      fileChunks: [],

      listaUpload: [],
      erroUpload: "",
      nomeArquivoTratado: false,
      cancelarUpload: false,

      carregandoDownload: {},

      tamanhoMaxUploadSimples: 100 * 1024 * 1024,
      tamanhoMaxChunk: 3 * 1024 * 1024,

      identificadorArquivoCommit: "",
    };
  },

  watch: {
    async usuarioLogado(newValue, oldValue) {
      if (!this.usuarioLogado.Grupos.includes("Gestores de Bases")) {
        this.$router.push("/inicio");
      }
    },   
  },

  methods: {
    async recuperaProjeto(callback) {
      const objetoHistorico = {
        identificadorProjeto: this.guidProjeto,
      };

      const query = new URLSearchParams(objetoHistorico);
      try {
        const respostaProjeto = await gestorProjetosService.recuperaProjeto(
          query.toString()
        );
        this.metadadosProjeto = respostaProjeto.MetadadosProjeto;
        this.arquivosEntradaProjeto = respostaProjeto.ArquivosProjeto;
        // this.arquivosSaidaProjeto = respostaProjeto.ArquivosSaida;
        // this.arquivosExcluidosProjeto = respostaProjeto.ArquivosExcluidos;
        this.objetoProjeto = respostaProjeto.ResumoProjeto;

        this.arquivosEntradaProjeto.forEach((file) => {
          this.$set(this.carregandoDownload, file.NomeArquivo, false);
        });

        this.arquivosEntradaProjeto.sort((a, b) => {
          return new Date(b.DataCriacao) - new Date(a.DataCriacao);
        });
        // this.arquivosExcluidosProjeto.sort((a, b) => {
        //   return new Date(b.DataCriacao) - new Date(a.DataCriacao);
        // });

        // this.arquivosSaidaProjeto.sort((a, b) => {
        //   return new Date(b.DataCriacao) - new Date(a.DataCriacao);
        // });
      } catch (error) {
        // this.$router.push("/gestor_bases/projetos");
      }

      if (typeof callback === "function") {
        callback();
      }
      this.loading = false;
    },

    reset() {
      this.file = null;
      this.fileChunks = [];
      this.nomeArquivoTratado = false;
    },

    async deletarPreAUpload(obj) {
      this.listaUpload = this.listaUpload.filter((item) => item !== obj);
    },

    async destruirArquivo(file) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["IdentificadorArquivo"] = file.IdentificadorArquivo;

      try {
        await gestorArquivosService.destroiArquivo(objetoConsulta);
      } catch (error) {
        throw error;
      }
    },

    async handleFileUpload(tipo, event) {
      this.reset();
      const arquivos = event.target.files;

      for (let i = 0; i < arquivos.length; i++) {
        this.file = arquivos[i];
        let nomeArquivoOriginal = this.file.name;
        let nomeArquivoTratado = nomeArquivoOriginal;

        // nomeArquivoTratado = nomeArquivoTratado.replace(/[^a-zA-Z0-9.]/g, "");
        nomeArquivoTratado = nomeArquivoTratado.replace(/[^a-zA-Z0-9._-]/g, "");

        const lastDotIndex = nomeArquivoTratado.lastIndexOf(".");
        if (lastDotIndex !== -1) {
          nomeArquivoTratado =
            nomeArquivoTratado.substring(0, lastDotIndex).replace(/\./g, "") +
            nomeArquivoTratado.substring(lastDotIndex);
        }

        if (nomeArquivoTratado !== nomeArquivoOriginal) {
          this.nomeArquivoTratado = true;
        }

        if (this.file) {
          if (this.file.size > this.tamanhoMaxUploadSimples) {
            await this.createChunks();
            await this.salvaArquivoLista(tipo, nomeArquivoTratado);
          } else {
            await this.salvaArquivoLista(tipo, nomeArquivoTratado);
          }
        }
      }
    },

    async cancelarUploadChunks() {
      this.cancelarUpload = true;
    },

    async cancelarUploadTodosArquivos() {
      for (const file of this.listaUpload) {
        if (file.status === "Na fila") {
          //continuar daqui
          // console.log("file a cancelar", file);
        }
      }

    },

    async uploadTodosArquivos() {
      for (const file of this.listaUpload) {
        if (file.status === "Aguardando envio") {
          // console.log("file antes", file);
          file.status = "Na fila";
        }
      }

      for (const file of this.listaUpload) {
        if (file.status === "Na fila") {
          // console.log("file na fila", file);
          file.status = "Na fila";
          await this.uploadChunks(file);
        }
      }
    },

    async uploadChunks(upload) {
      var id = upload.id;
      var tipo = upload.tipo;
      this.erroUpload = "";
      this.cancelarUpload = false;

      if (this.listaUpload[id].status != "Enviando") {
        this.listaUpload[id].status = "Enviando";
      }

      if (upload.tamanhoArquivo > this.tamanhoMaxUploadSimples) {
        while (
          upload.currentChunkIndex < this.listaUpload[id].quantidadeChunks
        ) {
          if (this.cancelarUpload) {
            this.listaUpload[id].status = "Cancelado";
            this.listaUpload[id].progresso = 0;

            await this.recuperaProjeto();

            if (
              this.arquivosEntradaProjeto.some(
                (arquivo) => arquivo.NomeArquivo === upload.NomeArquivo
              )
            ) {
              await this.destruirArquivo(upload);
            }

            break;
          }

          const chunk = this.listaUpload[id].chunks[upload.currentChunkIndex];

          try {
            if (tipo == "append") {
              await this.enviaArquivoChunkAppend(
                upload.NomeArquivo,
                upload.currentChunkIndex,
                chunk
              );
            }
            if (tipo == "block") {
              await this.enviaArquivoChunkBlock(chunk, upload);
            }

            upload.currentChunkIndex++;
            this.listaUpload[id].progresso = Math.floor(
              (upload.currentChunkIndex /
                this.listaUpload[id].quantidadeChunks) *
                100
            );
          } catch (error) {
            this.erroUpload = error.toString();
            this.listaUpload[id].progresso = 0;
            if (this.listaUpload[id].status != "Erro") {
              this.listaUpload[id].status = "Erro";
            }
          }
        }

        if (
          tipo == "block" &&
          this.listaUpload[id].progresso == 100 &&
          this.listaUpload[id].status != "Erro" &&
          upload.listaBlobIds.length == upload.quantidadeChunks
        ) {
          // console.log('listaBlobs', upload.listaBlobIds)
          var resultadoCommit = await this.enviaArquivoChunkBlockCommit(upload);
          // console.log("commit-resultado", resultadoCommit);
          this.identificadorArquivoCommit = "";

          if (resultadoCommit == true) {
            this.listaUpload[id].status = "Terminado";
          } else {
            this.listaUpload[id].progresso = 0;
            if (this.listaUpload[id].status != "Cancelado") {
              this.listaUpload[id].status = "Erro";
            }
          }
        }

        if (
          this.listaUpload[id].progresso == 100 &&
          this.listaUpload[id].status == "Enviando"
        ) {
          this.listaUpload[id].status = "Terminado";
        }
      } else {
        await this.enviaArquivoSimples(upload);
      }

      await this.recuperaProjeto();
    },

    async salvaArquivoLista(tipo, nome) {
      const ext = this.file.name.split(".");

      // Terminado
      // Enviando
      // Erro
      // Aguardando envio
      // Extensão não permitida
      // Arquivo inválido
      // Na fila

      const novoArquivo = {
        id: this.listaUpload.length,
        DataCriacao: this.dataHoraAtual(),
        NomeArquivo: nome,
        tamanhoArquivo: this.file.size,
        tipoArquivo: this.file.type,
        extensaoArquivo: ext[ext.length - 1],
        quantidadeChunks: this.fileChunks.length,
        chunks: this.fileChunks,
        progresso: 0,
        status: "Aguardando envio",
        tipo: tipo,
        jaExiste: false,
        currentChunkIndex: 0,
        listaBlobIds: [],
      };

      if (
        this.arquivosEntradaProjeto.some(
          (arquivo) =>
            arquivo.NomeArquivo.replace(/\s+/g, "") ===
            novoArquivo.NomeArquivo.replace(/\s+/g, "")
        )
      ) {
        novoArquivo.status = "Arquivo já adicionado";
        novoArquivo.jaExiste = true;
      }
      this.listaUpload.push(novoArquivo);
    },

    async enviaArquivoSimples(upload) {
      var id = upload.id;
      var objetoConsulta = {};

      const novoArquivo = new File([this.file], upload.NomeArquivo, {
        type: this.file.type,
        lastModified: this.file.lastModified,
      });

      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["Arquivo"] = novoArquivo;

      try {
        await gestorArquivosService.enviaUploadSimples(objetoConsulta);

        this.listaUpload[id].progresso = 100;
        if (this.listaUpload[id].status != "Terminado") {
          this.listaUpload[id].status = "Terminado";
        }
      } catch (error) {
        this.erroUpload = error.toString();
        this.listaUpload[id].progresso = 0;
        if (this.listaUpload[id].status != "Erro") {
          this.listaUpload[id].status = "Erro";
        }
        // throw error;
      }
    },

    async enviaArquivoChunkBlock(chunk, upload) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["NomeArquivo"] = upload.NomeArquivo;
      objetoConsulta["Caminho"] = "entrada/";
      objetoConsulta["IdChunk"] = upload.currentChunkIndex;
      objetoConsulta["Blob"] = chunk;

      try {
        const resultado = await gestorArquivosService.enviaUploadBlock(
          objetoConsulta
        );
        // console.log("block-resultado", resultado);
        upload.listaBlobIds.push(resultado.data.IdBloco);
        if (this.identificadorArquivoCommit == "") {
          this.identificadorArquivoCommit = resultado.data.IdentificadorArquivo;
        }
      } catch (error) {
        throw error;
      }
    },

    async enviaArquivoChunkBlockCommit(upload) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["IdentificadorArquivo"] = this.identificadorArquivoCommit;
      objetoConsulta["NomeArquivo"] = upload.NomeArquivo;
      objetoConsulta["Caminho"] = "entrada/";
      objetoConsulta["TamanhoBlocos"] = this.tamanhoMaxChunk;
      objetoConsulta["BlobIds"] = upload.listaBlobIds;
      objetoConsulta["Validade"] = "";

      try {
        const resultado = await gestorArquivosService.enviaUploadBlockCommit(
          objetoConsulta
        );
        return resultado;
      } catch (error) {
        return error;
        throw error;
      }
    },

    async createChunks() {
      this.fileChunks = [];
      const tamanhoArquivo = this.file.size;

      let start = 0;
      let end = this.tamanhoMaxChunk;

      while (start < tamanhoArquivo) {
        const chunk = this.file.slice(start, end);
        this.fileChunks.push(chunk);
        start = end;
        end = start + this.tamanhoMaxChunk;
      }

      // await this.downloadZip();
    },

    async downloadZip() {
      const zip = new JSZip();

      for (let index = 0; index < this.fileChunks.length; index++) {
        const chunk = this.fileChunks[index];
        zip.file(`chunk_${index + 1}`, chunk);
      }

      const zipContent = await zip.generateAsync({ type: "blob" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipContent);
      link.download = "chunks.zip";
      link.click();
    },

    async downloadArquivosSimples(file, index) {
      this.$set(this.carregandoDownload, file.NomeArquivo, true);

      const objetoHistorico = {
        IdentificadorProjeto: this.guidProjeto,
        NomeArquivo: file.NomeArquivo,
      };

      const query = new URLSearchParams(objetoHistorico);
      try {
        await gestorArquivosService.downloadArquivoSimples(
          query.toString(),
          objetoHistorico,
          (progresso) => {
            this.arquivosEntradaProjeto[index].Progresso = progresso;
            this.arquivosEntradaProjeto = [...this.arquivosEntradaProjeto];
          }
        );
      } catch (error) {
        throw error;
      }

      this.$set(this.carregandoDownload, file.NomeArquivo, false);
      this.arquivosEntradaProjeto[index].Progresso = 0;
      this.arquivosEntradaProjeto = [...this.arquivosEntradaProjeto];
    },

    async downloadArquivosStreaming(file, index) {
      this.$set(this.carregandoDownload, file.NomeArquivo, true);

      const objetoHistorico = {
        IdentificadorProjeto: this.guidProjeto,
        NomeArquivo: file.NomeArquivo,
        TamanhoArquivo: file.Tamanho,
      };

      const query = new URLSearchParams(objetoHistorico);
      try {
        await gestorArquivosService.downloadArquivoStreaming(
          query.toString(),
          objetoHistorico,
          (progresso) => {
            this.arquivosEntradaProjeto[index].Progresso = progresso;
            this.arquivosEntradaProjeto = [...this.arquivosEntradaProjeto];
          }
        );
      } catch (error) {
        throw error;
      }

      this.$set(this.carregandoDownload, file.NomeArquivo, false);
      this.arquivosEntradaProjeto[index].Progresso = 0;
      this.arquivosEntradaProjeto = [...this.arquivosEntradaProjeto];
    },

    async cancelarDownload(file, index) {
      this.$set(this.carregandoDownload, file.NomeArquivo, false);
      this.arquivosEntradaProjeto[index].Progresso = 0;
      this.arquivosEntradaProjeto = [...this.arquivosEntradaProjeto];

      await gestorArquivosService.cancelarRequisicaoSimples(file.NomeArquivo);
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getDate()).padStart(2, "0");
        const mes = String(data.getMonth() + 1).padStart(2, "0");
        const ano = data.getFullYear();

        const horas = String(data.getHours()).padStart(2, "0");
        const minutos = String(data.getMinutes()).padStart(2, "0");
        const segundos = String(data.getSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    dataHoraAtual() {
      const agora = new Date();
      const dia = String(agora.getDate()).padStart(2, "0");
      const mes = String(agora.getMonth() + 1).padStart(2, "0");
      const ano = agora.getFullYear();
      const horas = String(agora.getHours()).padStart(2, "0");
      const minutos = String(agora.getMinutes()).padStart(2, "0");
      const segundos = String(agora.getSeconds()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    },

    voltarProjetos() {
      this.$router.push("/gestor_bases/projetos");
    },

    resetProjeto() {
      this.guidProjeto = "";
      this.objetoProjeto = {};
      this.arquivosEntradaProjeto = [];
      this.listaUpload = [];
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  computed: {},

  async mounted() {
    this.loading = true;
    this.resetProjeto();
    this.guidProjeto = this.$route.params.guid;

    if (this.guidProjeto) {
      await this.recuperaProjeto();
    } else {
      this.$router.push(`/gestor_bases/projetos`);
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (this.listaUpload.some((arquivo) => arquivo.status == "Enviando")) {
      Swal.fire({
        icon: "warning",
        html: "<font size='5'>Atenção! Ao sair da página os uploads em andamento serão cancelados. </br> </font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Continuar',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Cancelar',
        cancelButtonAriaLabel: "Cancelar",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelarUpload = true;
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  async beforeDestroy() {
    var arquivo = this.listaUpload.find(
      (arquivo) => arquivo.status == "Enviando"
    );
    if (arquivo) {
      this.cancelarUpload = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.container-projeto {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 5px 20px;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.button-box {
  display: flex;
  gap: 10px;
}

.button-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;

  .button_nova {
    width: 120px;
    height: 35px;
    font-size: 12px;
    padding: 1px 5px;
    color: white;
    border: none;
    background-image: url(/img/tela-inicial/Botao-Nova.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.icone-voltar {
  font-size: 16px;
  transform: translateX(-7px);
}

.detalhes-projeto {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  display: flex;
  padding: 10px 25px 25px 25px;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  gap: 5px;

  &.dados {
    background-color: #3b3b3be0;
    color: white;
  }
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.col-cards {
  display: flex;
  // width: 80%;
  gap: 50px;
  padding: 0 0px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  &.info-guid {
    flex-direction: row;
    width: 230px;
  }
}

.col-card {
  display: flex;
  flex-direction: column;
  // min-width: 275px;
  gap: 5px;

  &.info-guid {
    flex-direction: row;
    width: 230px;
  }
}

.item {
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  &.titulo {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid white;
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .icone-mensagem {
    color: #ffee00;
    font-size: 18px;
    margin-left: 10px;
    cursor: default;
  }

  &.arquivos {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid white;
    width: 100%;
    justify-content: center;
    padding: 5px 10px;
  }
}

.bloco {
  display: flex;
  align-items: center;
  gap: 5px;
}

.titulo {
  font-weight: 400;

  &.data {
    margin: 10px 0;
  }

  &.secao {
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
  }
}

.texto {
  font-weight: 300;
}

.desc {
  font-size: 12px;
  font-weight: 300;
}

.icone-info-projeto {
  cursor: pointer;
  transition: 0.3s;
  font-weight: 300;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: white;
  opacity: 0.7;
}

.teste {
  cursor: default !important;
  pointer-events: none;
  color: white;
  opacity: 0.7;
}
</style>
