<template>
  <div>
    <div class="md-layout text-center" v-if="!loading">
      <div class="button-box">
        <div class="button-container">
          <button class="button_nova">
            <!-- @click="abreCriarNovoProjeto()" -->
            Novo Projeto
          </button>
        </div>
      </div>
      <div class="row" v-if="projetosUsuario.length > 0">
        <div class="tabela md-card">
          <div class="titulo-box">
            <div class="title">Projetos</div>
            <span
              class="resumo-status sumario"
              v-if="projetosUsuario.length > 0"
            >
              {{ resumoStatus }}
            </span>
            <span class="resumo-status sumario" v-else>
              Nenhum projeto criado
            </span>
          </div>
          <div class="projetos-menu">
            <div
              class="menu-tab"
              v-for="(menu, index) in menus"
              :key="index"
              :class="{ selecionado: menuSelecionado == menu }"
              @click="alteraMenu(menu, index)"
            >
              {{ menu }}
            </div>
          </div>
          <div class="tabela-box" v-if="projetosUsuarioFiltrado.length > 0">
            <div class="tabela-header">
              <div
                class="cabecalho"
                v-for="(cabecalho, i) in header"
                :key="i"
                :class="{ action: i == header.length - 1 }"
              >
                {{ cabecalho }}
              </div>
            </div>
            <div class="tabela-body">
              <MeusProjetos
                v-for="(projeto, index) in projetosUsuarioFiltrado"
                :key="index"
                :projeto="projeto"
                :index="index"
                :projetosUsuario="projetosUsuarioFiltrado"
                @recuperaHistoricoProjetos="recuperaHistoricoProjetos"
              />
            </div>
          </div>
          <div v-else class="md-card aviso">Nenhum projeto encontrado</div>
        </div>
      </div>
      <div v-else class="md-card aviso">Nenhum projeto criado</div>
    </div>
    <div class="spinnerBG" v-if="loading">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { gestorProjetosService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import MeusProjetos from "@/components/GestorBases/MeusProjetos.vue";
import Loading from "@/components/Loading";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import Swal from "sweetalert2";

export default {
  name: "AreaTransferencia",

  components: { MeusProjetos, Loading },

  data() {
    return {
      nomeProjeto: "",
      projetosUsuario: [],
      projetosUsuarioFiltrado: [],
      header: [
        "Data de criação",
        "Nome do projeto",
        "Código",
        // "Tipo",
        // "Status",
        "Última versão",
        "Ações",
      ],

      menus: ["Ativos", "Excluídos", "Compartilhados"],
      opcoesTipo: ["Processamento", "ProcessamentoContinuo"],
      menuSelecionado: "Ativos",

      loading: false,
    };
  },

  watch: {
    async usuarioLogado(newValue, oldValue) {
      if (newValue) {
        await this.recuperaHistoricoProjetos();
      }
      if (this.usuarioLogado && Array.isArray(this.usuarioLogado.Grupos)) {
        if (this.usuarioLogado) {
          if (!this.usuarioLogado.Grupos.includes("Gestores de Bases")) {
            this.$router.push("/inicio");
          }
        }
      }
    },
  },

  computed: {
    resumoStatus() {
      const statusCounts = {
        ativos: 0,
        excluidos: 0,
      };

      statusCounts.ativos = 0;
      statusCounts.excluidos = 0;
      statusCounts.compartilhados = 0;

      this.projetosUsuario.forEach((projeto) => {
        if (projeto.StatusId === "Ativo") {
          statusCounts.ativos += 1;
        } else if (projeto.StatusId === "Removido") {
          statusCounts.excluidos += 1;
        } else if (projeto.StatusId === "Compartilhado") {
          statusCounts.compartilhados += 1;
        }
      });
      const result = [];

      let isFirstItem = true;

      if (statusCounts.ativos > 0) {
        result.push(
          `${statusCounts.ativos} ${isFirstItem ? "projeto(s)" : ""} ativo(s)`
        );
        isFirstItem = false;
      }
      if (statusCounts.excluidos > 0) {
        result.push(
          `${statusCounts.excluidos} ${
            isFirstItem ? "projeto(s)" : ""
          } excluído(s)`
        );
        isFirstItem = false;
      }
      if (statusCounts.compartilhados > 0) {
        result.push(
          `${statusCounts.compartilhados} ${
            isFirstItem ? "projeto(s)" : ""
          } compartilhado(s)`
        );
        isFirstItem = false;
      }

      return result.join(", ");
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
    }),
  },

  methods: {
    alteraMenu(menu, index) {
      this.menuSelecionado = menu;
      if (menu == "Ativos") {
        this.projetosUsuarioFiltrado = this.projetosUsuario.filter(
          (projeto) => projeto.StatusId == "Ativo"
        );
      }
      if (menu == "Excluídos") {
        this.projetosUsuarioFiltrado = this.projetosUsuario.filter(
          (projeto) => projeto.StatusId == "Removido"
        );
      }
      if (menu == "Compartilhados") {
        this.projetosUsuarioFiltrado = this.projetosUsuario.filter(
          (projeto) => projeto.StatusId == "Compartilhado"
        );
      }
    },

    async abreCriarNovoProjeto() {
      const { value: nomeProjeto } = await Swal.fire({
        title: "Novo Projeto",
        html: `<div class="input-container">
        <div class="swal-campo">
          <label id="nomeProjetoLabel" class="swal-nameProject">
            Nome do Projeto (3 a 120 caracteres)
          </label>
          <input type="text" id="nomeProjetoInput" class="swal2-input-projeto" maxlength="120" />
        </div>
        <div class="swal-campo">
          <label id="tipoProjetoLabel" class="swal-nameProject">
            Tipo do Projeto
          </label>
          <select id="tipoProjetoSelect" class="swal2-input-projeto" disabled>
          ${this.opcoesTipo
            .map((opcao) => `<option value="${opcao}">${opcao}</option>`)
            .join("")}
          </select>      
        </div>
      </div>`,
        confirmButtonText: "Criar",
        showCancelButton: true,
        preConfirm: () => {
          const nomeProjeto = document.getElementById("nomeProjetoInput").value;
          // const tipoProjeto = document.getElementById("tipoProjetoInput").value;
          const nomeProjetoLabel = document.getElementById("nomeProjetoLabel");
          if (nomeProjeto.length < 3 || nomeProjeto.length > 120) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Número de caracteres inválido";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else if (
            !/^[A-Za-z0-9._\s\-@çãõáéíóúâêôàèìòùÇÃÕÁÉÍÓÚÂÊÔÀÈÌÒÙ]*$/.test(
              nomeProjeto
            ) ||
            /\s{2,}/.test(nomeProjeto)
          ) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Caracteres inválidos";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else if (
            this.projetosUsuario.some(
              (projeto) => projeto.NomeProjeto === nomeProjeto
            )
          ) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Esse nome já está em uso";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else {
            nomeProjetoLabel.classList.remove("cor-vermelho");
          }
          return nomeProjeto;
        },
      });

      if (nomeProjeto) {
        this.nomeProjeto = nomeProjeto;
        this.enviaProjeto();
      }
    },

    async enviaProjeto() {
      if (this.nomeProjeto.length > 2 && this.nomeProjeto != "") {
        await gestorProjetosService.criaProjeto(this.nomeProjeto).then(
          (resultado) => {
            if (resultado.status == 200) {
              this.confirmAlert(true);
            } else {
              this.confirmAlert(false);
            }
          },
          (erro) => {
            this.confirmAlert(false);
          }
        );

        await this.recuperaHistoricoProjetos();

        this.nomeProjeto = "";
      }
    },

    dataHoraAtual() {
      const agora = new Date();
      const dia = String(agora.getDate()).padStart(2, "0");
      const mes = String(agora.getMonth() + 1).padStart(2, "0");
      const ano = agora.getFullYear();
      const horas = String(agora.getHours()).padStart(2, "0");
      const minutos = String(agora.getMinutes()).padStart(2, "0");
      const segundos = String(agora.getSeconds()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    },

    async recuperaHistoricoProjetos() {
      this.loading = true;

      const objetoHistorico = {
        // nomeUsuario: this.usuarioLogado.Usuario,
      };
      const query = new URLSearchParams(objetoHistorico);
      const respostaListaProjetos =
        await gestorProjetosService.recuperaListaProjetos(query.toString());

      this.projetosUsuario = respostaListaProjetos.Projetos;
      this.projetosUsuario = this.projetosUsuario.sort(
        (a, b) =>
          new Date(b.ProjetoCriadoEmDataBrt) -
          new Date(a.ProjetoCriadoEmDataBrt)
      );

      this.projetosUsuarioFiltrado = this.projetosUsuario.filter(
        (projeto) => projeto.StatusId == "Ativo"
      );

      this.menuSelecionado = "Ativos";
      this.loading = false;
    },

    confirmAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Ação realizada com sucesso!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {},
          didClose: () => {},
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didClose: () => {},
        });
      }
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    // await this.recuperaHistoricoProjetos();
  },
};
</script>

<style lang="scss" scoped>
.button-box {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  // border-bottom: 1px dashed #0070c0;
  flex-direction: column;
}
.button-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;

  .button_nova {
    width: 140px;
    height: 35px;
    font-size: 12px;
    padding: 1px 5px;
    color: white;
    border: none;
    background-image: url(/img/tela-inicial/Botao-Nova.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.input-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

progress {
  width: 100%;
  height: 20px;
}
.campo {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 350px;
  gap: 10px;
}

.error {
  color: #b72026 !important;
}

.icon {
  color: gray;

  &.send {
    position: relative;
    top: 5px;
  }
}

.row {
  display: flex;
  width: 100%;
}

.title {
  color: #0070c0;
  display: flex;
  width: 100%;
  margin: 0px 0;
  font-size: 20px;
  justify-content: flex-start;
  align-items: center;
}

.titulo-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #0070c0;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.resumo-status {
  position: absolute;
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  top: 25px;
  cursor: default;

  &.sumario {
    left: 200px;
  }
}

.projetos-menu {
  display: flex;
  gap: 10px;
}

.menu-tab {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border: 1px solid #959595;
  border-radius: 5px;
  font-weight: 300;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.5s;
  width: 120px;

  &.selecionado {
    opacity: 1;
    border: 1px solid #272727;
    font-weight: 400;
  }

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  &:active {
    transform: scale(0.95);
    opacity: 1;
  }
}

.tabela {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  gap: 5px;
  padding: 10px 25px 25px 25px;
}

.tabela-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabela-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .cabecalho {
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    font-weight: 600;
  }
}

.tabela-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.aviso {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  line-height: 16px;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: #b6b6b6;
}
</style>

<style>
.swal-campo {
  margin-top: 10px;
}

.swal-nameProject {
  font-size: 14px;
}

.cor-vermelho {
  color: #b71c1c;
}

.swal2-input-projeto {
  margin: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #cbbfbf;
  border-radius: 0;
  width: 100%;
  height: 30px;
}
</style>
