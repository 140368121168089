<template>
  <div class="md-layout tableResult">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Histórico Gestor de bases</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <div class="md-layout boxLotes">
                <div class="selectInfoLotes">
                  <div
                    class="boxInfo md-layout-item md-size-25 md-medium-size-40 md-xsmall-size-100"
                  >
                    <md-field>
                      <label for="tipoListaHistorico"> Tipo </label>
                      <md-select
                        v-model="tipoListaHistorico"
                        name="tipoListaHistorico"
                        id="tipoListaHistorico"
                      >
                        <md-option value="arquivos">Arquivos</md-option>
                        <md-option value="projetos">Projetos</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <md-field
                    class="boxInfo md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                    v-if="tipoListaHistorico == 'projetos'"
                  >
                    <label for="idenditificadorProjeto">
                      Identificação Projeto
                    </label>
                    <md-input
                      v-model="idenditificadorProjeto"
                      @keyup.enter="recuperaHistorico"
                    ></md-input>
                  </md-field>
                </div>
                <div class="selectInfoLotes">
                  <div
                    class="boxInfo md-layout-item md-size-25 md-medium-size-40 md-xsmall-size-100"
                  >
                    <md-field>
                      <label for="periodoPreDefinidoHistorico">
                        Período a analisar
                      </label>
                      <md-select
                        v-model="periodoPreDefinidoHistorico"
                        name="periodoPreDefinidoHistorico"
                        id="periodoPreDefinidoHistorico"
                      >
                        <md-option value="diaAtual">Hoje</md-option>
                        <md-option value="semanaAtual">Esta semana</md-option>
                        <md-option value="mesAtual">Este mês</md-option>
                        <md-option value="semanaAnterior">
                          Semana passada
                        </md-option>
                        <md-option value="mesAnterior">Mês passado</md-option>
                        <md-option value="custom">Customizado</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div
                    class="boxInfo md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                    v-if="periodoPreDefinidoHistorico != 'custom'"
                  >
                    <md-field>
                      <md-input
                        disabled
                        :value="periodoDe + ' até ' + periodoAte"
                      ></md-input>
                    </md-field>
                  </div>
                  <div
                    class="boxInfo md-layout-item md-size-35 md-small-size-50 md-xsmall-size-100"
                    v-if="periodoPreDefinidoHistorico == 'custom'"
                  >
                    <date-range-picker
                      class="selectPeriod"
                      @atualizouData="periodoFiltroAtualizado"
                      :periodoDe="periodoDeToDate()"
                      :periodoAte="periodoAteToDate()"
                    />
                  </div>
                </div>
                <div
                  class="md-layout-item md-size-50 md-medium-size-50 md-xsmall-size-100"
                  v-if="periodoPreDefinidoHistorico == 'custom'"
                >
                  <span class="layout-warning"
                    >*Período máximo para o filtro Customizado é de 60 dias.
                  </span>
                </div>
                <div
                  class="md-layout-item md-size-25 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-button
                    class="md-primary md-sm"
                    :disabled="dadosFiltroCarregando"
                    @click.native.prevent="recuperaHistorico"
                  >
                    Filtrar
                    <md-icon>update</md-icon></md-button
                  >
                </div>
                <div
                  class="md-layout-item md-size-15 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-progress-spinner
                    v-if="dadosFiltroCarregando"
                    :md-diameter="30"
                    :md-stroke="2"
                    md-mode="indeterminate"
                  >
                  </md-progress-spinner>
                  <small v-if="mensagemCarregamentoFiltro">
                    {{ mensagemCarregamentoFiltro }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card class="tableResult" id="tabela_historico" style="z-index: 0">
        <md-card-content>
          <md-card
            class="conteudo-horizontal-centralizado"
            v-if="resultadoHistorico.length == 0"
          >
            <md-card-content> Nenhum resultado encontrado. </md-card-content>
          </md-card>

          <div
            class="tabela-historico"
            v-if="
              resultadoHistorico &&
              resultadoHistorico.length != 0 &&
              liberaTabelaArquivos
            "
          >
            <table
              id="tableComponent"
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th
                    v-for="field in header"
                    :key="field"
                    class="text-center"
                    style="border: none"
                  >
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <LinhasHistoricoArquivos
                v-for="(item, index) in resultadoHistorico"
                :key="index"
                :item="item"
                :index="index"
                :resultadoHistorico="resultadoHistorico"
              ></LinhasHistoricoArquivos>
            </table>
          </div>
          <div
            class="tabela-historico"
            v-if="
              resultadoHistorico &&
              resultadoHistorico.length != 0 &&
              liberaTabelaProjetos
            "
          >
            <table
              id="tableComponent"
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th
                    v-for="field in header"
                    :key="field"
                    class="text-center"
                    style="border: none"
                  >
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <LinhasHistoricoProjetos
                v-for="(item, index) in resultadoHistorico"
                :key="index"
                :item="item"
                :index="index"
                :resultadoHistorico="resultadoHistorico"
              ></LinhasHistoricoProjetos>
            </table>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { DateRangePicker } from "@/components";
import { gestorHistoricoService } from "@/services";
import { historicoService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import LinhasHistoricoArquivos from "@/components/GestorBases/Historico/LinhasHistoricoArquivos.vue";
import LinhasHistoricoProjetos from "@/components/GestorBases/Historico/LinhasHistoricoProjetos.vue";

export default {
  name: "ListaHistoricoProjetos",

  components: {
    DateRangePicker,
    LinhasHistoricoArquivos,
    LinhasHistoricoProjetos,
  },

  data() {
    return {
      periodoDe: "",
      periodoAte: "",
      periodoPreDefinidoHistorico: "",
      tipoListaHistorico: "",
      idenditificadorProjeto: "",

      resultadoHistorico: [],
      liberaTabelaArquivos: true,
      liberaTabelaProjetos: false,
      mensagemCarregamentoFiltro: "",

      dadosFiltroCarregando: false,

      counter: 0,

      header: ["Data da versão", "Nome do Arquivo", "Status", "Tipo"],
      headerArquivos: ["Data da versão", "Nome do Arquivo", "Status", "Tipo"],
      headerProjetos: ["Data da versão", "Nome do Arquivo", "Status"],
    };
  },

  methods: {
    periodoDeToDate: function () {
      var resultado = this.$moment(this.periodoDe, "DD/MM/YYYY").toDate();
      return resultado;
    },
    periodoAteToDate: function () {
      var resultado = this.$moment(this.periodoAte, "DD/MM/YYYY").toDate();
      return resultado;
    },

    async periodoFiltroAtualizado(a) {
      this.periodoDe = a.periodoDe;
      this.periodoAte = a.periodoAte;
    },

    preenchePeriodoInicial() {
      this.periodoDe = this.$moment()
        .locale("pt-BR")
        .startOf("week")
        .format("DD/MM/YYYY");
      this.periodoAte = this.$moment().format("DD/MM/YYYY");
    },

    async recuperaHistorico() {
      this.dadosFiltroCarregando = true;
      this.resultadoHistorico = [];

      var periodoDeFormatado = this.$moment(
        this.periodoDe,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      var periodoAteFormatado = this.$moment(
        this.periodoAte,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      var objetoConsulta = {};
      objetoConsulta["HistoricoDe"] = periodoDeFormatado;
      objetoConsulta["HistoricoAte"] = periodoAteFormatado;
      objetoConsulta["IdenditificadorProjeto"] = this.idenditificadorProjeto;

      if (this.tipoListaHistorico == "arquivos") {
        this.liberaTabelaArquivos = true;
        this.liberaTabelaProjetos = false;
        this.header = this.headerArquivos;
        try {
          const resultado =
            await gestorHistoricoService.recuperaHistoricoArquivos(
              objetoConsulta
            );
          this.resultadoHistorico = resultado.data.Historico;
        } catch (error) {
          throw error;
        }
      }
      if (this.tipoListaHistorico == "projetos") {
        this.header = this.headerProjetos;
        this.liberaTabelaArquivos = false;
        this.liberaTabelaProjetos = true;
        try {
          const resultado =
            await gestorHistoricoService.recuperaHistoricoProjetos(
              objetoConsulta
            );
          this.resultadoHistorico = resultado.data.Historico;
          this.idenditificadorProjeto = "";
        } catch (error) {
          throw error;
        }
      }

      this.dadosFiltroCarregando = false;
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  async created() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };
    this.recebeContexto(contexto);

    this.periodoPreDefinidoHistorico = "semanaAtual";
    this.tipoListaHistorico = "arquivos";
    this.periodoDe = this.$moment()
      .locale("pt-BR")
      .startOf("week")
      .format("DD/MM/YYYY");
    this.periodoAte = this.$moment().format("DD/MM/YYYY");
    this.idenditificadorProjeto = "";

    await this.recuperaHistorico();
  },

  destroyed() {
    this.idenditificadorProjeto = "";
  },

  watch: {
    async periodoPreDefinidoHistorico(periodoSelecionado) {
      switch (periodoSelecionado) {
        case "mesAtual":
          this.periodoDe = "01/" + this.$moment().format("MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "mesAnterior":
          var dataMesAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ months: 1 });
          this.periodoDe = "01/" + dataMesAnterior.format("MM/YYYY");
          this.periodoAte = dataMesAnterior.endOf("month").format("DD/MM/YYYY");

          break;
        case "semanaAtual":
          this.periodoDe = this.$moment()
            .locale("pt-BR")
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "semanaAnterior":
          var dataSemanaAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ weeks: 1 });
          this.periodoDe = dataSemanaAnterior
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = dataSemanaAnterior
            .endOf("week")
            .format("DD/MM/YYYY");

          break;
        case "diaAtual":
          this.periodoDe = this.$moment().format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;

        case "custom":
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tableResult {
  z-index: 0;
}

.boxLotes {
  display: flex;
  flex-direction: column;
}

.boxInfo {
  margin: 10px 30px 0 0;
  padding: 5px 0;
}
.selectInfoLotes {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  width: 70%;

  @include mediaQueryMax(small) {
    flex-direction: column;
    width: 90%;
  }
}

.layout-warning {
  position: relative;
  bottom: 15px;
  font-size: 11px;
  opacity: 0.5;
}
</style>
